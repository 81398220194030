import React from "react"
import styled from 'styled-components';
import { Byline } from '../styles/typography';
import { FlexBlock, Spacer, Wrapper } from '../styles/layout';
import IconSchoger from "../ui/icons/IconSchoger";

const Footer = () => (
  <StyledFooter>
    <Wrapper>
      <FlexBlock direction="column" justify="center">
        <FlexBlock style={{ width: '200px' }}>
          <a
            href="mailto:ned@golfbeta.io"
            target="_blank"
            rel="noreferrer noopener"
          >

            <IconSchoger icon="mail" />
          </a>
          <a
            href="https://www.twitter.com/golfBeta_io"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconSchoger icon="twitter" />
          </a>
          <a
            href="https://www.instagram.com/golfbeta.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconSchoger icon="instagram" />
          </a>
          <a
            href="https://www.facebook.com/gbgolftraining"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconSchoger icon="facebook-circle" />

          </a>
        </FlexBlock>
        <Spacer height={1.6} />
        <Byline color="offWhite">
          © 2021 golfBeta. All rights reserved.
        </Byline>
      </FlexBlock>
    </Wrapper>
  </StyledFooter>
)

export default Footer

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.primaryDark};
`;