import React from 'react';

const IconList = [
  {
    icon: 'add',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M13 11h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4H7a1 1 0 010-2h4V7a1 1 0 012 0z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'add-circle',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-add-circle"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
        />
      </svg>
    ),
  },
  {
    icon: 'add-circle-large',
    svg: (
      <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path
            d="M32.833 27.167h11.334a2.833 2.833 0 1 1 0 5.666H32.833v11.334a2.833 2.833 0 1 1-5.666 0V32.833H15.833a2.833 2.833 0 1 1 0-5.666h11.334V15.833a2.833 2.833 0 1 1 5.666 0v11.334z"
            id="b"
          />
          <filter
            x="-20.6%"
            y="-14.7%"
            width="141.2%"
            height="141.2%"
            filterUnits="objectBoundingBox"
            id="a"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
          <circle className="primary" cx="30" cy="30" r="28" />
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use className="secondary" xlinkHref="#b" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'add-square',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <rect className="primary" height="18" rx="2" width="18" x="3" y="3" />
          <path
            d="M13 11h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4H7a1 1 0 010-2h4V7a1 1 0 012 0z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'brain',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
          <path
            d="M20.506 9.471a4.99 4.99 0 00-1.538-2.988A4.978 4.978 0 0015.55 5.13c-.197 0-.4.014-.616.042a5.592 5.592 0 00-6.995.162 6.584 6.584 0 00-6.062 5.66A3.938 3.938 0 001 13.47c0 2.115 1.68 3.851 3.776 3.94a6.553 6.553 0 003.629 1.09 6.56 6.56 0 004.4-1.69h4.155c.601.33 1.275.504 1.957.504A4.087 4.087 0 0023 13.23c0-1.652-.99-3.125-2.494-3.759zm-1.589 6.878c-.554 0-1.102-.15-1.584-.436a.482.482 0 00-.246-.067h-3.62a2.323 2.323 0 00-.525-2.486.458.458 0 00-.648.648c.267.267.414.621.414.998 0 .333-.114.648-.325.9a.481.481 0 00-.099.073 5.603 5.603 0 01-3.879 1.557 5.596 5.596 0 01-3.202-1.002c-.665-.745-.635-1.841.05-2.526a.458.458 0 10-.648-.648 2.756 2.756 0 00-.625 2.927 2.987 2.987 0 01-2.016-2.818c0-.717.261-1.412.736-1.955a.482.482 0 00.115-.262 5.617 5.617 0 014.387-4.84 2.667 2.667 0 00.605 2.849.456.456 0 00.648 0 .458.458 0 000-.648 1.744 1.744 0 01-.515-1.241c0-.425.15-.827.427-1.146a.48.48 0 00.084-.06 4.632 4.632 0 015.407-.554 2.058 2.058 0 00-.848 1.936c.03.23.226.4.454.4.309-.036.486-.266.454-.516a1.142 1.142 0 01.987-1.276.46.46 0 00.068-.015c.207-.03.397-.046.577-.046 1.91 0 3.54 1.351 3.941 3.17a2.331 2.331 0 00-2.185 1.492.458.458 0 10.855.329 1.414 1.414 0 011.624-.872.48.48 0 00.12.06 3.114 3.114 0 012.131 2.956c0 1.72-1.399 3.12-3.119 3.12z"
            className="primary"
          />
          <g className="secondary">
            <path d="M19.859 12.614c-.933 0-1.692.76-1.692 1.692a.458.458 0 10.916 0c0-.428.348-.776.776-.776a.458.458 0 100-.916zM9.317 12.066a2.011 2.011 0 00-2.009 2.009.458.458 0 10.916 0c0-.603.49-1.094 1.093-1.094a.458.458 0 100-.915zM14.79 10.595a2.4 2.4 0 00-3.17-1.205.458.458 0 10.376.836c.745-.335 1.624-.001 1.959.744a.458.458 0 10.835-.375z" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'calendar-date',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M5 4h14a2 2 0 012 2v13a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2zm0 5v10h14V9z"
            className="secondary"
          />
          <path
            d="M13 13h3v3h-3zM7 2a1 1 0 011 1v3a1 1 0 11-2 0V3a1 1 0 011-1zm10 0a1 1 0 011 1v3a1 1 0 01-2 0V3a1 1 0 011-1z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'chart',
    svg: (
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M2 0h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm11 4a1 1 0 0 0-1 1v8a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1z"
            className="primary"
          />
          <path
            d="M5 8a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4-2a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'chat',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-chat"
      >
        <path
          className="primary"
          d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1-2-2z"
        />
        <path
          className="secondary"
          d="M6 7h12a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2zm0 4h8a1 1 0 0 1 0 2H6a1 1 0 0 1 0-2z"
        />
      </svg>
    ),
  },
  {
    icon: 'chevron-down',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.293 10.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L12 13.586z"
          className="primary"
          fillRule="evenodd"
        />
      </svg>
    ),
  },
  {
    icon: 'chevron-down-circle',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-cheveron-down-circle"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        />
      </svg>
    ),
  },
  {
    icon: 'chevron-left-circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="primary" r="10" />
          <path
            d="M13.707 15.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L10.414 12z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'chevron-right-circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="primary" r="10" />
          <path
            d="M10.293 8.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L13.586 12z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'chevron-right',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path
          className="primary"
          fillRule="evenodd"
          d="M10.293 8.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L13.586 12l-3.293-3.293z"
        />
      </svg>
    ),
  },
  {
    icon: 'chevron-up-circle',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-cheveron-up-circle"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
        />
      </svg>
    ),
  },
  {
    icon: 'circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" className="primary" fillRule="evenodd" r="10" />
      </svg>
    ),
  },
  {
    icon: 'close',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-close"
      >
        <path
          className="secondary"
          fillRule="evenodd"
          d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
        />
      </svg>
    ),
  },
  {
    icon: 'close-circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="primary" r="10" />
          <path
            d="M13.414 12l2.829 2.828a1 1 0 01-1.415 1.415L12 13.414l-2.828 2.829a1 1 0 11-1.415-1.415L10.586 12 7.757 9.172a1 1 0 011.415-1.415L12 10.586l2.828-2.829a1 1 0 011.415 1.415z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'collection',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <rect
            className="secondary"
            height="12"
            rx="2"
            width="20"
            x="2"
            y="10"
          />
          <path
            d="M20 8H4a2 2 0 012-2h12a2 2 0 012 2zm-2-4H6a2 2 0 012-2h8a2 2 0 012 2z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'drag-updown',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.707 9.707a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 11-1.414 1.414L12 6.414zm6.586 4.586a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L12 17.586z"
          className="primary"
          fillRule="evenodd"
        />
      </svg>
    ),
  },
  {
    icon: 'driving',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M15 1v14h-2V1zM20 20.712V17h-1v4.455h1z"
            className="secondary"
          />
          <g className="primary">
            <path d="M19.5 18a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM12.595 22.155c4.27 0 3.866-2.198 3.866-4.908 0-2.71.404-4.908-3.866-4.908C8.325 12.34 1 14.537 1 17.247c0 2.71 7.327 4.908 11.596 4.908z" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'edit',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M4 14a1 1 0 01.293-.707l11-11a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-11 11A1 1 0 018 18H5a1 1 0 01-1-1z"
            className="secondary"
            fillRule="nonzero"
          />
          <rect className="primary" height="2" rx="1" width="20" x="2" y="20" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'facebook-circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <rect className="secondary" width="24" height="24" rx="12" />
          <path
            d="M13.44 3c-3.241 0-4.311 1.487-4.311 4.037v2.018H7v3.03h2.129v9.082h3.926v-9.082h2.669l.358-3.03h-3.027v-1.8c0-.814.179-1.227 1.442-1.227h1.585V3H13.44z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'instagram',
    svg: (
      <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <path
            d="M11 0C4.925 0 0 4.924 0 11c0 6.075 4.925 11 11 11s11-4.925 11-11c0-6.076-4.926-11-11-11z"
            className="secondary"
          />
          <path
            d="M17.533 9.756v5.262A2.486 2.486 0 0 1 15.05 17.5H7.166a2.485 2.485 0 0 1-2.483-2.482V7.134A2.485 2.485 0 0 1 7.166 4.65h7.884a2.486 2.486 0 0 1 2.483 2.483v2.622z"
            className="primary"
          />
          <path
            d="M11.108 13.337a2.264 2.264 0 0 1-2.261-2.262c0-.492.16-.948.427-1.319a2.26 2.26 0 0 1 1.834-.943 2.26 2.26 0 0 1 1.834.943c.268.371.428.827.428 1.32a2.264 2.264 0 0 1-2.262 2.26zm3.514-2.262a3.48 3.48 0 0 0-.26-1.319h1.918v5.262c0 .678-.552 1.23-1.23 1.23H7.166c-.679 0-1.231-.552-1.231-1.23V9.756h1.918a3.517 3.517 0 0 0 3.255 4.833 3.518 3.518 0 0 0 3.514-3.514zm1.424-2.767l-2.17.007-.008-2.177 1.895-.007h.283v2.177z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-1',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <path
            d="M14 19h-4l.682-1.548.59-1.452h1.455l.591 1.452z"
            fill="#ff7058"
          />
          <path
            d="M16.202 18H7.837c-.46 0-.837.424-.837.941V22h9.997v-3.059c.042-.517-.335-.941-.795-.941z"
            fill="#cd7f32"
          />
          <path
            d="M14 18.163c-.552.279-1.103.558-1.655.767L12 19l-.345-.14c-.552-.209-1.103-.488-1.655-.767L10.897 16h2.137z"
            fill="#d0021b"
          />
          <g fillRule="nonzero" fill="#a56424">
            <path d="M2 4.286V5.99c0 4.155 2.581 7.73 6.203 9.226 1.165.498 2.456.748 3.788.748s2.623-.25 3.788-.748c3.622-1.496 6.203-5.07 6.203-9.226V4.286zM20.317 5.99a8.212 8.212 0 01-1.915 5.278c-1.54 1.828-3.83 3.033-6.41 3.033a8.25 8.25 0 01-6.412-3.033C4.415 9.855 3.665 7.984 3.665 5.99v-.042h16.61v.042zM18.485 2H5.497c-.292 0-.5.25-.5.499 0 .29.208.54.5.54h12.988c.292 0 .5-.25.5-.499 0-.29-.208-.54-.5-.54z" />
          </g>
          <path
            d="M19 3v4.513c0 4.18-2.782 7.983-7 9.487-4.218-1.546-7-5.307-7-9.528V3z"
            fill="#cd7f32"
          />
          <path
            d="M12 5l.955 3.048H16l-2.455 1.904.91 3.048L12 11.095 9.545 13l.91-3.048L8 8.048h3.045z"
            fill="#fff"
          />
          <path d="M5 3h14v1H5z" fill="#bc742d" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-2',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
          <path
            d="M14 19h-4l.682-1.548.59-1.452h1.455l.591 1.452z"
            fill="#ff7058"
          />
          <path
            d="M16.202 18H7.837c-.46 0-.837.424-.837.941V22h9.997v-3.059c.042-.517-.335-.941-.795-.941z"
            fill="silver"
          />
          <path
            d="M14 18.163c-.552.279-1.103.558-1.655.767L12 19l-.345-.14c-.552-.209-1.103-.488-1.655-.767L10.897 16h2.137z"
            fill="#9013fe"
          />
          <g fill="#969595">
            <path d="M2 4.286V5.99c0 4.155 2.581 7.73 6.203 9.226 1.165.498 2.456.748 3.788.748s2.623-.25 3.788-.748c3.622-1.496 6.203-5.07 6.203-9.226V4.286zM20.317 5.99a8.212 8.212 0 01-1.915 5.278c-1.54 1.828-3.83 3.033-6.41 3.033a8.25 8.25 0 01-6.412-3.033C4.415 9.855 3.665 7.984 3.665 5.99v-.042h16.61v.042zM18.485 2H5.497c-.292 0-.5.25-.5.499 0 .29.208.54.5.54h12.988c.292 0 .5-.25.5-.499 0-.29-.208-.54-.5-.54z" />
          </g>
          <path
            d="M19 3v4.513c0 4.18-2.782 7.983-7 9.487-4.218-1.546-7-5.307-7-9.528V3z"
            fill="silver"
          />
          <path
            d="M12 5l.955 3.048H16l-2.455 1.904.91 3.048L12 11.095 9.545 13l.91-3.048L8 8.048h3.045z"
            fill="#fff"
          />
          <path d="M5 3h14v1H5z" fill="#afaeae" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-3',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
          <path
            d="M14 19h-4l.682-1.548.59-1.452h1.455l.591 1.452z"
            fill="#ff7058"
          />
          <path
            d="M16.202 18H7.837c-.46 0-.837.424-.837.941V22h9.997v-3.059c.042-.517-.335-.941-.795-.941z"
            fill="#ffd15c"
          />
          <path
            d="M14 18.163c-.552.279-1.103.558-1.655.767L12 19l-.345-.14c-.552-.209-1.103-.488-1.655-.767L10.897 16h2.137z"
            fill="#f1543f"
          />
          <g fill="#f8b64c">
            <path d="M2 4.286V5.99c0 4.155 2.581 7.73 6.203 9.226 1.165.498 2.456.748 3.788.748s2.623-.25 3.788-.748c3.622-1.496 6.203-5.07 6.203-9.226V4.286zM20.317 5.99a8.212 8.212 0 01-1.915 5.278c-1.54 1.828-3.83 3.033-6.41 3.033a8.25 8.25 0 01-6.412-3.033C4.415 9.855 3.665 7.984 3.665 5.99v-.042h16.61v.042zM18.485 2H5.497c-.292 0-.5.25-.5.499 0 .29.208.54.5.54h12.988c.292 0 .5-.25.5-.499 0-.29-.208-.54-.5-.54z" />
          </g>
          <path
            d="M19 3v4.513c0 4.18-2.782 7.983-7 9.487-4.218-1.546-7-5.307-7-9.528V3z"
            fill="#ffd15c"
          />
          <path
            d="M12 5l.955 3.048H16l-2.455 1.904.91 3.048L12 11.095 9.545 13l.91-3.048L8 8.048h3.045z"
            fill="#fff"
          />
          <path d="M6 3h13v1H6z" fill="#ffc952" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-normal',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="background" r="12" />
          <circle cx="8.5" cy="12.5" className="secondary" r="4.5" />
          <circle cx="15.5" cy="12.5" className="primary" r="4.5" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-repeatThis',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="background" r="12" />
          <path
            d="M8.832 7.283a6.583 6.583 0 013.901-1.28c2.702 0 5.11 1.653 6.173 4.157a1.168 1.168 0 01-.58 1.52 1.11 1.11 0 01-1.477-.595c-.709-1.67-2.314-2.773-4.116-2.773-.819 0-1.601.226-2.28.64l.83.853c.706.728.206 1.972-.794 1.972H7.122c-.62 0-1.122-.517-1.122-1.155V7.157c0-1.029 1.209-1.544 1.916-.816zm5.715 8.765l-.83-.854c-.707-.728-.207-1.972.793-1.972h3.368c.62 0 1.122.517 1.122 1.155v3.466c0 1.029-1.209 1.544-1.916.816l-.916-.942a6.584 6.584 0 01-3.902 1.28c-2.7 0-5.107-1.65-6.171-4.151-.25-.585.01-1.266.577-1.522s1.23.01 1.479.595c.71 1.668 2.314 2.768 4.115 2.768.819 0 1.601-.226 2.28-.639z"
            className="secondary"
            fillRule="nonzero"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-repeatPrev',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="background" r="12" />
          <path
            d="M8.832 7.283a6.583 6.583 0 013.901-1.28c2.702 0 5.11 1.653 6.173 4.157a1.168 1.168 0 01-.58 1.52 1.11 1.11 0 01-1.477-.595c-.709-1.67-2.314-2.773-4.116-2.773-.819 0-1.601.226-2.28.64l.83.853c.706.728.206 1.972-.794 1.972H7.122c-.62 0-1.122-.517-1.122-1.155V7.157c0-1.029 1.209-1.544 1.916-.816z"
            className="primary"
            fillRule="nonzero"
          />
          <circle cx="6" cy="16" className="secondary" r="4" />
          <circle cx="18" cy="16" className="secondary" r="4" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-repeatAll',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="background" r="12" />
          <path
            d="M8.832 7.283a6.583 6.583 0 013.901-1.28c2.702 0 5.11 1.653 6.173 4.157a1.168 1.168 0 01-.58 1.52 1.11 1.11 0 01-1.477-.595c-.709-1.67-2.314-2.773-4.116-2.773-.819 0-1.601.226-2.28.64l.83.853c.706.728.206 1.972-.794 1.972H7.122c-.62 0-1.122-.517-1.122-1.155V7.157c0-1.029 1.209-1.544 1.916-.816z"
            className="primary"
            fillRule="nonzero"
          />
          <circle cx="12" cy="16" className="secondary" r="4" />
          <circle cx="6" cy="16" className="primary" r="4" />
          <circle cx="18" cy="16" className="secondary" r="4" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'level-isKiller',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="background" r="12" />
          <circle cx="5.5" cy="12.5" className="secondary" r="4.5" />
          <circle cx="18.5" cy="12.5" className="secondary" r="4.5" />
          <circle cx="12.5" cy="12.5" className="primary" r="5.5" />
          <g stroke="#fff" strokeLinecap="round">
            <path d="M10.5 10.5l4 4M10.5 14.5l4-4" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'light',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M5 8a7 7 0 1110.621 5.992l-.64 3.204A1 1 0 0114 18h-4a1 1 0 01-.98-.804l-.641-3.204A6.997 6.997 0 015 8zm12 0a5 5 0 00-5-5 1 1 0 000 2 3 3 0 013 3 1 1 0 002 0z"
            className="primary"
          />
          <path
            d="M15 21a2 2 0 01-2 2h-2a2 2 0 01-2-2 1 1 0 010-2h6a1 1 0 010 2z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'lightning',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-bolt"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M14 10h2a1 1 0 0 1 .81 1.58l-5 7A1 1 0 0 1 10 18v-4H8a1 1 0 0 1-.81-1.58l5-7A1 1 0 0 1 14 6v4z"
        />
      </svg>
    ),
  },
  {
    icon: 'lightning-large',
    svg: (
      <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path
            id="b"
            d="M37.298 24.644h5.798c2.358 0 3.73 2.692 2.359 4.63L30.96 49.768c-1.64 2.318-5.258 1.147-5.258-1.701V36.356h-5.798c-2.358 0-3.73-2.692-2.359-4.63L32.04 11.232c1.64-2.318 5.258-1.147 5.258 1.701z"
          />
          <filter id="a" height="134.1%" width="148.3%" x="-24.1%" y="-12.2%">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="2"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="30" cy="30" className="primary" r="29" />
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use className="secondary" xlinkHref="#b" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'mail',
    svg: (
      <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M20 4.618V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4.618l9.553 4.776a1 1 0 0 0 .894 0L20 4.618z"
            className="primary"
            fillRule="nonzero"
          />
          <path
            d="M10 7.382l-10-5V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v.382l-10 5z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'menu',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-menu"
      >
        <path
          className="secondary"
          fillRule="evenodd"
          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        />
      </svg>
    ),
  },
  {
    icon: 'play',
    svg: (
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
          <circle className="primary" cx="12" cy="12" r="10" />
          <path
            d="M15.514 11.143a1 1 0 0 1 0 1.714l-5 3A1 1 0 0 1 9 15V9a1 1 0 0 1 1.514-.857l5 3z"
            className="secondary"
            fillRule="nonzero"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'pressure',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M3 11h2a1 1 0 010 2H3zm3.343-6.071l1.414 1.414a1 1 0 01-1.414 1.414L4.93 6.343zM13 3v2a1 1 0 01-2 0V3zm6.071 3.343l-1.414 1.414a1 1 0 11-1.414-1.414l1.414-1.414zM21 13h-2a1 1 0 010-2h2z"
            className="primary"
          />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-6.93-6h13.86a8 8 0 10-13.86 0z"
            className="secondary"
          />
          <path
            d="M12.68 14.087a.998.998 0 01.239-.236l4.915-3.441a1 1 0 111.147 1.638l-4.915 3.442a1.006 1.006 0 01-.15.087 2 2 0 11-1.235-1.49z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'random',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M16.586 7l-2.293-2.293C13.663 4.077 14.109 3 15 3h6a1 1 0 011 1v6c0 .89-1.077 1.337-1.707.707L18 8.414l-4.293 4.293a1 1 0 01-1.414 0L9 9.414l-5.293 5.293a1 1 0 11-1.414-1.414l6-6a1 1 0 011.414 0L13 10.586z"
            className="primary"
          />
          <path
            d="M16.586 17L13 13.414l-3.293 3.293a1 1 0 01-1.414 0l-6-6a1 1 0 011.414-1.414L9 14.586l3.293-3.293a1 1 0 011.414 0L18 15.586l2.293-2.293c.63-.63 1.707-.184 1.707.707v6a1 1 0 01-1 1h-6c-.89 0-1.337-1.077-.707-1.707z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'refresh',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-refresh"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M8.52 7.11a5.98 5.98 0 0 1 8.98 2.5 1 1 0 1 1-1.83.8 4 4 0 0 0-5.7-1.86l.74.74A1 1 0 0 1 10 11H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1.7-.7l.82.81zm5.51 8.34l-.74-.74A1 1 0 0 1 14 13h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1.7.7l-.82-.81A5.98 5.98 0 0 1 6.5 14.4a1 1 0 1 1 1.83-.8 4 4 0 0 0 5.7 1.85z"
        />
      </svg>
    ),
  },
  {
    icon: 'remove-circle',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-remove-circle"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <rect width="12" height="2" x="6" y="11" className="secondary" rx="1" />
      </svg>
    ),
  },
  {
    icon: 'remove-square',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <rect className="primary" height="18" rx="2" width="18" x="3" y="3" />
          <path d="M7 11h10a1 1 0 010 2H7a1 1 0 010-2z" className="secondary" />
        </g>
      </svg>
    ),
  },
  {
    icon: 'rocket-large',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.016 512.016">
        <path
          d="M17.25 493.132c3.625-10.188 8.344-23.141 13.625-36.563 19.875-50.642 30.407-65.782 34.938-70.298 6.781-6.797 15.188-11.375 24.313-13.266l3.156-.656 35.344-35.75 42.312 48.875-32.906 31.516-.688 3.235c-1.875 9.125-6.469 17.531-13.25 24.344-4.531 4.5-19.656 15.062-70.282 34.923-13.436 5.265-26.406 10.015-36.562 13.64z"
          fill="#ffce54"
        />
        <path
          d="M129.158 320.943L87.97 362.584c-10.719 2.219-21.126 7.609-29.688 16.172C36.407 400.63 0 510.366 0 510.366s109.72-36.391 131.626-58.282c8.531-8.547 13.938-18.969 16.156-29.703l37.812-36.22-56.436-65.218zm3.906 86.062l-4.781 4.594-1.344 6.484c-1.469 7.079-5.062 13.642-10.375 18.954-1.75 1.75-13.219 11.578-66.563 32.517a1148.888 1148.888 0 01-14.906 5.703 995.301 995.301 0 015.719-14.876c20.938-53.36 30.75-64.829 32.531-66.579 5.313-5.328 11.876-8.906 18.938-10.359l6.312-1.312 4.531-4.578 24.969-25.281 28.156 32.516-23.187 22.217z"
          fill="#f6bb42"
        />
        <g fill="#da4453">
          <path d="M199.909 423.397a452.13 452.13 0 0017.875-8.876l121.501-86.781a664.598 664.598 0 0014.719-14.203c2.781-2.812 5.563-5.625 8.282-8.469-.469 55.359-25.845 115.923-74.032 164.127-16.062 16.047-33.469 29.562-51.625 40.484-.125.078-.845.5-.845.5-4.031 2.188-9.188 1.578-12.594-1.828a10.548 10.548 0 01-2.438-3.875s-.375-1.109-.469-1.594l-21.938-78.767c.533-.234 1.033-.484 1.564-.718zM207.534 150.269a711.587 711.587 0 00-8.469 8.312 596.797 596.797 0 00-14.188 14.703c-21.281 3-86.812 121.517-86.812 121.517a487.923 487.923 0 00-8.875 17.876c-.25.516-.469 1.031-.719 1.547L9.688 292.285c-.469-.094-1.594-.469-1.594-.469a10.504 10.504 0 01-3.875-2.453c-3.406-3.406-4-8.547-1.812-12.594 0 0 .406-.703.5-.828 10.906-18.157 24.406-35.563 40.469-51.625 48.219-48.219 108.782-73.594 164.158-74.047z" />
        </g>
        <path
          d="M197.003 151.05c-60.408 60.422-103.97 129.438-128.252 196.299-1.281 3.75-.469 8.031 2.531 11.016l82.907 82.938c3 2.969 7.281 3.797 11.031 2.516 66.876-24.282 135.877-67.829 196.285-128.251 93.876-93.845 146.563-207.081 150.501-303.645.125-2.875-.906-6.047-3.094-8.25-2.219-2.203-5.375-3.234-8.281-3.109-96.562 3.937-209.783 56.641-303.628 150.486z"
          fill="#e6e9ed"
        />
        <g fill="#434a54">
          <path d="M317.598 237.535c-11.375 0-22.062-4.438-30.094-12.469-8.031-8.047-12.469-18.735-12.469-30.11s4.438-22.063 12.469-30.11c8.031-8.031 18.75-12.469 30.094-12.469 11.375 0 22.062 4.438 30.125 12.469 16.595 16.61 16.595 43.625 0 60.22-8.062 8.031-18.75 12.469-30.094 12.469h-.031zM227.284 327.849c-11.375 0-22.062-4.422-30.094-12.469-8.032-8.031-12.47-18.735-12.47-30.095 0-11.375 4.438-22.078 12.47-30.125 8.031-8.031 18.719-12.469 30.094-12.469 11.376 0 22.063 4.438 30.126 12.469 16.594 16.61 16.594 43.626 0 60.22-8.063 8.047-18.75 12.469-30.126 12.469z" />
        </g>
        <g fill="#ccd1d9">
          <path d="M355.254 157.331c-10.062-10.047-23.438-15.594-37.656-15.594-14.188 0-27.562 5.547-37.625 15.594-10.031 10.047-15.594 23.422-15.594 37.625 0 14.219 5.562 27.579 15.594 37.641 10.062 10.046 23.438 15.578 37.625 15.578 14.219 0 27.594-5.531 37.656-15.578 20.751-20.75 20.751-54.515 0-75.266zm-15.062 60.204a31.875 31.875 0 01-22.594 9.359c-8.156 0-16.344-3.125-22.562-9.359-12.469-12.469-12.469-32.688 0-45.157 6.219-6.234 14.406-9.344 22.562-9.344 8.188 0 16.344 3.109 22.594 9.344 12.468 12.469 12.468 32.688 0 45.157zM227.284 232.067c-14.219 0-27.562 5.531-37.626 15.578-10.062 10.046-15.594 23.422-15.594 37.641 0 14.204 5.531 27.563 15.594 37.626 10.063 10.047 23.407 15.594 37.626 15.594 14.22 0 27.595-5.547 37.658-15.594 20.75-20.75 20.75-54.517 0-75.267-10.063-10.047-23.438-15.578-37.658-15.578zm22.595 75.782c-6.25 6.25-14.407 9.359-22.595 9.359-8.156 0-16.344-3.109-22.562-9.359-12.47-12.47-12.47-32.688 0-45.157 6.219-6.235 14.406-9.344 22.562-9.344 8.188 0 16.345 3.109 22.595 9.344 12.469 12.468 12.469 32.687 0 45.157z" />
        </g>
        <path
          d="M479.225 145.816L366.755 33.361C412.568 13.439 458.225 2.298 500.631.564c2.906-.125 6.062.906 8.281 3.109 2.188 2.203 3.219 5.375 3.094 8.25-1.719 42.438-12.875 88.08-32.781 133.893z"
          fill="#da4453"
        />
      </svg>
    ),
  },
  {
    icon: 'sad',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-mood-sad"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M16.47 18H7.53a1 1 0 0 1-.75-1.67 6.98 6.98 0 0 1 10.44 0 1 1 0 0 1-.75 1.67zM8.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        />
      </svg>
    ),
  },
  {
    icon: 'score',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-target"
      >
        <path
          className="primary"
          d="M15.23 2.53l-.35.35a3 3 0 0 0-.8 1.4 8.01 8.01 0 1 0 5.64 5.63 3 3 0 0 0 1.4-.79l.35-.35A9.99 9.99 0 0 1 12 22a10 10 0 1 1 3.23-19.47zM13.55 6.2L11.75 8a4 4 0 1 0 4.24 4.25l1.8-1.8a6 6 0 1 1-4.24-4.25z"
        />
        <path
          className="secondary"
          d="M16 6.59V5a1 1 0 0 1 .3-.7l2-2A1 1 0 0 1 20 3v1h1a1 1 0 0 1 .7 1.7l-2 2a1 1 0 0 1-.7.3h-1.59l-4.7 4.7a1 1 0 0 1-1.42-1.4L16 6.58z"
        />
      </svg>
    ),
  },
  {
    icon: 'search',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-search"
      >
        <circle cx="10" cy="10" r="7" className="primary" />
        <path
          className="secondary"
          d="M16.32 14.9l1.1 1.1c.4-.02.83.13 1.14.44l3 3a1.5 1.5 0 01-2.12 2.12l-3-3a1.5 1.5 0 01-.44-1.14l-1.1-1.1a8 8 0 111.41-1.41zM10 16a6 6 0 100-12 6 6 0 000 12z"
        />
      </svg>
    ),
  },
  {
    icon: 'session',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 13h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4a2 2 0 012-2zm10 0h4a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4a2 2 0 012-2zM5 3h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm10 0h4a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2V5a2 2 0 012-2z"
          className="secondary"
          fillRule="evenodd"
        />
      </svg>
    ),
  },
  {
    icon: 'session-balance',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M20.423 6.907a.993.993 0 01.071.148l1.774 1.773a1 1 0 01-1.414 1.415L19.5 8.889l-1.354 1.354a1 1 0 01-1.414-1.415l1.774-1.773A.993.993 0 0118.529 7H13v12h7.414a1 1 0 010 2h-16a1 1 0 010-2H11V7H5.446a.993.993 0 01.023.055l1.774 1.773a1 1 0 01-1.415 1.415L4.475 8.889 3.12 10.243a1 1 0 11-1.414-1.415l1.774-1.773a.992.992 0 01.077-.158A1 1 0 014 5h5.414a1 1 0 110-2H11V2a1 1 0 012 0v1h1.414a1 1 0 010 2H20a1 1 0 01.423 1.907z"
            className="primary"
          />
          <g className="secondary">
            <path d="M7.667 10c.214 0-1.769 3-3.334 3C2.493 13 1 10.407 1 10zM22.667 10c.214 0-1.769 3-3.334 3C17.493 13 16 10.407 16 10z" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'session-create',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M5 13h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4a2 2 0 012-2zm10 0h4a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4a2 2 0 012-2zM5 3h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2z"
            className="secondary"
          />
          <path
            d="M18 6h2a1 1 0 010 2h-2v2a1 1 0 01-2 0V8h-2a1 1 0 010-2h2V4a1 1 0 012 0z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'session-create-large',
    svg: (
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M79.875 20.125h10.75a5.375 5.375 0 010 10.75h-10.75v10.75a5.375 5.375 0 01-10.75 0v-10.75h-10.75a5.375 5.375 0 010-10.75h10.75V9.375a5.375 5.375 0 0110.75 0z"
            fill="#e91e63"
          />
          <rect
            fill="#bd10e0"
            height="42.667"
            rx="10"
            width="42.667"
            x="3"
            y="4"
          />
          <rect
            fill="#4a90e2"
            height="42.667"
            rx="10"
            width="42.667"
            x="52.333"
            y="53.333"
          />
          <rect
            fill="#9013fe"
            height="42.667"
            rx="10"
            width="42.667"
            x="3"
            y="53.333"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'session-large',
    svg: (
      <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <rect id="b" x="2" y="20" width="57" height="12" rx="1" />
          <filter
            x="-12.3%"
            y="-41.7%"
            width="124.6%"
            height="216.7%"
            filterUnits="objectBoundingBox"
            id="a"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M33.139 26.91c-.028 1.52-1.326 2.731-2.9 2.705-1.574-.027-2.827-1.28-2.8-2.8l.422-23.353c.028-1.52 1.326-2.73 2.9-2.704 1.574.027 2.827 1.28 2.8 2.8l-.422 23.353z"
            id="d"
          />
          <filter
            x="-114.3%"
            y="-17.3%"
            width="328.7%"
            height="148.5%"
            filterUnits="objectBoundingBox"
            id="c"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M9.333 24C6.94 24 5 22.21 5 20s1.94-4 4.333-4c2.394 0 4.334 1.79 4.334 4s-1.94 4-4.334 4zM18 24c-2.393 0-4.333-1.79-4.333-4s1.94-4 4.333-4c2.393 0 4.333 1.79 4.333 4s-1.94 4-4.333 4zm8.667 0c-2.394 0-4.334-1.79-4.334-4s1.94-4 4.334-4C29.06 16 31 17.79 31 20s-1.94 4-4.333 4zm8.666 0C32.94 24 31 22.21 31 20s1.94-4 4.333-4c2.394 0 4.334 1.79 4.334 4s-1.94 4-4.334 4zM44 24c-2.393 0-4.333-1.79-4.333-4s1.94-4 4.333-4c2.393 0 4.333 1.79 4.333 4s-1.94 4-4.333 4zm8.667 0c-2.394 0-4.334-1.79-4.334-4s1.94-4 4.334-4C55.06 16 57 17.79 57 20s-1.94 4-4.333 4z"
            className="primary"
          />
          <path
            d="M58.375 26.196l-8.01 30.945c-.317 1.226-1.457 2.085-2.765 2.085H13.4c-1.308 0-2.448-.86-2.765-2.085l-8.01-30.945h55.75zm-44.89 14.43l2.85 11.01c.382 1.475 1.93 2.372 3.456 2.003 1.527-.369 2.456-1.863 2.074-3.338l-2.85-11.01c-.382-1.474-1.93-2.371-3.456-2.002-1.527.368-2.456 1.863-2.074 3.337zm28.5-1.335l-2.85 11.01c-.382 1.475.547 2.97 2.074 3.338 1.527.369 3.074-.528 3.456-2.003l2.85-11.01c.382-1.474-.547-2.969-2.074-3.337-1.527-.37-3.074.528-3.456 2.002zm-14.335.668v11.01c0 1.52 1.276 2.752 2.85 2.752s2.85-1.232 2.85-2.752v-11.01c0-1.52-1.276-2.753-2.85-2.753s-2.85 1.233-2.85 2.753z"
            className="primary"
            fillRule="nonzero"
          />
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use className="secondary" xlinkHref="#b" />
          <g fillRule="nonzero">
            <use fill="#000" filter="url(#c)" xlinkHref="#d" />
            <use className="primary" xlinkHref="#d" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'shopping-cart',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M7 4h14a1 1 0 01.894 1.447l-4 8A1 1 0 0117 14H7a1 1 0 01-1-1V5a1 1 0 011-1z"
            className="primary"
            fillRule="nonzero"
          />
          <path
            d="M17.732 19a2 2 0 11-3.465 0H8.733a2 2 0 11-3.417-.078A3.001 3.001 0 015 13.17V4H3a1 1 0 110-2h3a1 1 0 011 1v10h11a1 1 0 010 2H6a1 1 0 000 2h12a1 1 0 010 2z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'task',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm2 3a1 1 0 00-1 1v4a1 1 0 001 1h3a1 1 0 001-1V7a1 1 0 00-1-1z"
            className="secondary"
          />
          <path
            d="M7 14h10a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1v-2a1 1 0 011-1zm7-8h3a1 1 0 010 2h-3a1 1 0 010-2zm0 4h3a1 1 0 010 2h-3a1 1 0 010-2z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'task-create',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm2 3a1 1 0 00-1 1v4a1 1 0 001 1h3a1 1 0 001-1V7a1 1 0 00-1-1z"
            className="secondary"
          />
          <g className="primary">
            <rect height="4" rx="1" width="12" x="6" y="14" />
            <path d="M15.75 8.25h1.5a.75.75 0 110 1.5h-1.5v1.5a.75.75 0 11-1.5 0v-1.5h-1.5a.75.75 0 110-1.5h1.5v-1.5a.75.75 0 111.5 0z" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'task-create-large',
    svg: (
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M14 4h73c5.523 0 10 4.477 10 10v73c0 5.523-4.477 10-10 10H14C8.477 97 4 92.523 4 87V14C4 8.477 8.477 4 14 4zm11.5 15.5a6 6 0 00-6 6v19a6 6 0 006 6h13.833a6 6 0 006-6v-19a6 6 0 00-6-6z"
            fill="#c0d9e7"
          />
          <rect fill="#9013fe" height="12" rx="6" width="62" x="20" y="61" />
          <rect fill="#9013fe" height="12" rx="6" width="62" x="20" y="77" />
          <path
            d="M70.375 30.625h7.75a3.875 3.875 0 010 7.75h-7.75v7.75a3.875 3.875 0 01-7.75 0v-7.75h-7.75a3.875 3.875 0 010-7.75h7.75v-7.75a3.875 3.875 0 017.75 0z"
            fill="#e91e63"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'task-large',
    svg: (
      <svg viewBox="0 0 56 58" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path
            d="M18.667 2h22.666C44.463 2 47 4.507 47 7.6v19.6C47 36.478 39.389 44 30 44s-17-7.522-17-16.8V7.6C13 4.507 15.537 2 18.667 2z"
            id="b"
          />
          <filter
            x="-20.6%"
            y="-11.9%"
            width="141.2%"
            height="133.3%"
            filterUnits="objectBoundingBox"
            id="a"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M14 8v5.556H5.6v11.11c0 3.069 2.507 5.556 5.6 5.556H14v5.556h-2.8C5.014 35.778 0 30.803 0 24.667V13.556C0 10.487 2.507 8 5.6 8H14zm28 5.556V8h8.4c3.093 0 5.6 2.487 5.6 5.556v11.11c0 6.137-5.014 11.112-11.2 11.112H42v-5.556h2.8c3.093 0 5.6-2.487 5.6-5.555V13.556H42zM31.8 52.444H41a1 1 0 0 1 1 1V57a1 1 0 0 1-1 1H15a1 1 0 0 1-1-1v-3.556a1 1 0 0 1 1-1h9.2a1 1 0 0 0 1-1v-10.11h5.6v10.11a1 1 0 0 0 1 1z"
            className="secondary"
          />
          <g transform="translate(-2)">
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use className="primary" xlinkHref="#b" />
          </g>
        </g>
      </svg>
    ),
  },
  {
    icon: 'time',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-time"
      >
        <circle cx="12" cy="12" r="10" className="primary" />
        <path
          className="secondary"
          d="M13 11.59l3.2 3.2a1 1 0 0 1-1.4 1.42l-3.5-3.5A1 1 0 0 1 11 12V7a1 1 0 0 1 2 0v4.59z"
        />
      </svg>
    ),
  },
  {
    icon: 'tick',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" className="primary" r="10" />
          <path
            d="M10 14.586l6.293-6.293a1 1 0 011.414 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'target',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-target"
      >
        <path
          className="primary"
          d="M15.23 2.53l-.35.35a3 3 0 0 0-.8 1.4 8.01 8.01 0 1 0 5.64 5.63 3 3 0 0 0 1.4-.79l.35-.35A9.99 9.99 0 0 1 12 22a10 10 0 1 1 3.23-19.47zM13.55 6.2L11.75 8a4 4 0 1 0 4.24 4.25l1.8-1.8a6 6 0 1 1-4.24-4.25z"
        />
        <path
          className="secondary"
          d="M16 6.59V5a1 1 0 0 1 .3-.7l2-2A1 1 0 0 1 20 3v1h1a1 1 0 0 1 .7 1.7l-2 2a1 1 0 0 1-.7.3h-1.59l-4.7 4.7a1 1 0 0 1-1.42-1.4L16 6.58z"
        />
      </svg>
    ),
  },
  {
    icon: 'templates',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M2.594 6.086l9-4a1 1 0 01.812 0l9 4c.792.352.792 1.476 0 1.828l-9 4a1 1 0 01-.812 0l-9-4c-.792-.352-.792-1.476 0-1.828z"
            className="primary"
          />
          <path
            d="M3.913 10.5l7.68 3.414a1 1 0 00.813 0l7.681-3.414 1.32.586c.791.352.791 1.476 0 1.828l-9 4a1 1 0 01-.813 0l-9-4c-.792-.352-.792-1.476 0-1.828zm0 5l7.68 3.414a1 1 0 00.813 0l7.681-3.414 1.32.586c.791.352.791 1.476 0 1.828l-9 4a1 1 0 01-.813 0l-9-4c-.792-.352-.792-1.476 0-1.828z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'trash',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon-trash"
      >
        <path
          className="primary"
          d="M5 5h14l-.89 15.12a2 2 0 0 1-2 1.88H7.9a2 2 0 0 1-2-1.88L5 5zm5 5a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-1-1zm4 0a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-1-1z"
        />
        <path
          className="secondary"
          d="M8.59 4l1.7-1.7A1 1 0 0 1 11 2h2a1 1 0 0 1 .7.3L15.42 4H19a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2h3.59z"
        />
      </svg>
    ),
  },
  {
    icon: 'twitter',
    svg: (
      <svg viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 2.117c-.81.359-1.68.6-2.592.71A4.52 4.52 0 0 0 21.392.33a9.042 9.042 0 0 1-2.866 1.095A4.506 4.506 0 0 0 15.23 0c-2.914 0-5.055 2.719-4.397 5.541A12.813 12.813 0 0 1 1.532.826a4.52 4.52 0 0 0 1.396 6.026 4.494 4.494 0 0 1-2.043-.565c-.05 2.091 1.449 4.048 3.62 4.483a4.524 4.524 0 0 1-2.04.077 4.517 4.517 0 0 0 4.218 3.134A9.075 9.075 0 0 1 0 15.851a12.778 12.778 0 0 0 6.919 2.028c8.38 0 13.115-7.078 12.829-13.426A9.19 9.19 0 0 0 22 2.117z"
          className="primary"
          fillRule="nonzero"
        />
      </svg>
    ),
  },
  {
    icon: 'user-circle',
    svg: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M3.659 17.518A9.953 9.953 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 2.04-.61 3.936-1.659 5.518C19.48 16.013 17.858 16 16 16H8c-1.858 0-3.48.013-4.341 1.518z"
            className="secondary"
          />
          <path
            d="M3.659 17.518A4.998 4.998 0 018 15h8a4.998 4.998 0 014.341 2.518A9.99 9.99 0 0112 22a9.99 9.99 0 01-8.341-4.482zM12 13a4 4 0 110-8 4 4 0 010 8z"
            className="primary"
          />
        </g>
      </svg>
    ),
  },
  {
    icon: 'variable',
    svg: (
      <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M1.707 16.707a1 1 0 1 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L11 12.586l4.293-4.293a1 1 0 0 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0L7 11.414l-5.293 5.293z"
            className="primary"
          />
          <path
            d="M14.586 4l-2.293-2.293C11.663 1.077 12.109 0 13 0h6a1 1 0 0 1 1 1v6c0 .89-1.077 1.337-1.707.707L16 5.414l-4.293 4.293a1 1 0 0 1-1.414 0L7 6.414l-5.293 5.293a1 1 0 1 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L11 7.586 14.586 4z"
            className="secondary"
          />
        </g>
      </svg>
    ),
  },
];

export default IconList;
