import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { IconButton } from '../../styles/buttons';
import IconList from './IconList';

const IconSchoger = (props) => {
  const child = props.icon
    ? IconList.find((icon) => icon.icon === props.icon).svg
    : props.children;
  if (props.button)
    return (
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.buttonAction(e);
        }}
        border={props.border}
      >
        <StyledIcon {...props}>{child}</StyledIcon>
      </IconButton>
    );
  return (
    <>
      <StyledIcon {...props}>{child}</StyledIcon>
    </>
  );
};

IconSchoger.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.object,
  button: PropTypes.bool,
  buttonAction: PropTypes.func,
  hover: PropTypes.bool,
  faded: PropTypes.bool,
  icon: PropTypes.string,
  context: PropTypes.string,
  animation: PropTypes.string,
  border: PropTypes.bool,
};

IconSchoger.defaultProps = {
  width: '24px',
  height: '24px',
  button: false,
  buttonAction: () => { },
  hover: false,
  faded: false,
  icon: '',
  context: '',
  children: {},
  animation: 'none',
  border: false,
};

const iconColorChanger = (context, props) => {
  switch (context) {
    case 'light':
      return [
        props.theme.colors.white,
        props.theme.colors.offWhite,
        props.theme.colors.offWhite,
        props.theme.colors.white,
      ];
    case 'faded':
      return [
        props.theme.colors.lightestgrey,
        props.theme.colors.lightergrey,
        props.theme.colors.lightergrey,
        props.theme.colors.lightestgrey,
      ];
    case 'primary':
      return [
        props.theme.colors.lightergrey,
        props.theme.primary,
        props.theme.primary,
        props.theme.colors.lightergrey,
      ];
    case 'secondary':
      return [
        props.theme.colors.lightergrey,
        props.theme.secondary,
        props.theme.secondary,
        props.theme.colors.lightergrey,
      ];
    case 'success':
      return [
        props.theme.colors.greenLight,
        props.theme.colors.greenDark,
        props.theme.colors.greenDark,
        props.theme.colors.greenLight,
      ];
    case 'danger':
      return [
        props.theme.colors.lightred,
        props.theme.colors.red,
        props.theme.colors.red,
        props.theme.colors.lightred,
      ];
    case 'yellow':
      return [
        props.theme.colors.yellow,
        props.theme.primary,
        props.theme.primary,
        props.theme.colors.yellow,
      ];

    default:
      return [
        props.theme.primaryLight,
        props.theme.primary,
        props.theme.primary,
        props.theme.primaryLight,
      ];
  }
};

const fade = (primaryColor, secondaryColor) => keyframes`
0% {
fill:${primaryColor};
}
50% {
 fill:${secondaryColor};
}
100%{
  fill:${primaryColor};
}
`;

const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 1;
  transform-origin: center;
}
100% {
  transform: scale(4.5);
  opacity: 0;
  transform-origin: center;
}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const animationSelector = (props) => {
  switch (props.animation) {
    case 'fade':
      return css`
        ${fade(
        iconColorChanger(props.context, props)[1],
        props.theme.accent
      )} infinite 2s linear
      `;

    default:
      return 'none';
  }
};

const StyledIcon = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  transition: all ${(props) => props.theme.transitions};
  > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  .primary {
    transition: all ${(props) => props.theme.transitions};
    fill: ${(props) => iconColorChanger(props.context, props)[0]};
  }

  .secondary {
    transition: all ${(props) => props.theme.transitions};
    fill: ${(props) => iconColorChanger(props.context, props)[1]};
    animation: ${(props) => animationSelector(props)};
  }

  .background {
    transition: all ${(props) => props.theme.transitions};
    fill: ${(props) => props.theme.colors.lightestgrey};
  }
  &:hover {
    .primary {
      fill: ${(props) =>
    props.hover
      ? iconColorChanger(props.context, props)[2]
      : iconColorChanger(props.context, props)[0]};
    }

    .secondary {
      fill: ${(props) =>
    props.hover
      ? iconColorChanger(props.context, props)[3]
      : iconColorChanger(props.context, props)[1]};
    }
    .background {
      fill: ${(props) => props.theme.colors.lightergrey};
    }
  }
`;

export default IconSchoger;
