import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useLockBodyScroll from '../../hooks/useLockBodyScroll';

const bgVariant = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.overlayDark};
  transition: all 0.2s ease-out;
  z-index: 1;
`;

const ModalBackground = ({ on, toggle }) => {

  useLockBodyScroll(on)

  return (
    <AnimatePresence>
      {on && (
        <Background
          key="bg"
          variants={bgVariant}
          initial="exit"
          animate="enter"
          exit="exit"
          onClick={toggle}
        />
      )}
    </AnimatePresence>
  )

}

ModalBackground.propTypes = {
  on: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ModalBackground;
