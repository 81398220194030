import React from "react"
import styled from 'styled-components';

const Header = ({ children }) => (
  <StyledHeader className="site-header">
    {children}
  </StyledHeader>
)

export default Header

const StyledHeader = styled.header`
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.desktop}) {
    grid-template: auto / 1fr 1fr 40px;
  }
`