import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled, { createGlobalStyle, ThemeProvider } from "styled-components"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation";

import { theme } from '../styles/theme';

import "../assets/scss/style.scss"
import Footer from "./footer";
import { Button } from "../styles/buttons"

import * as analytics from '../analytics/analytics'
import { GridBlock } from "../styles/layout"

const query = graphql`
query LayoutQuery {
  site {
    siteMetadata {
      siteTitle: title
    }
  }
}
`

const GlobalStyle = createGlobalStyle`


html {
font-family: 'Avenir', sans-serif;
box-sizing: border-box;
font-size: 10px;
text-rendering: optimizeLegibility;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
*, *:before, *:after {
box-sizing: inherit;
}

main {
  position: relative;
}

*:focus {
outline: none;
}

::placeholder {
color: ${theme.placeholder};
}

body {
padding: 0;
margin: 0;
font-size: 1.5rem;
line-height: 2;
background-color: ${theme.colors.offWhite};

}
#root {
background-color: ${theme.bg};
}
a {
text-decoration: none;
color: ${theme.primary};
font-weight: bold;
&:hover {
 text-decoration: underline;
 text-decoration-color: ${theme.accent}
}
}
h1 {
font-size: 4rem;
color: ${theme.primaryText};
font-weight: 600;
/* height: 46px; */
line-height: 46px;
/* overflow: hidden; */
text-overflow: ellipsis;
/* margin: 0px; */
}
h2 {
font-size: 2.6rem;
line-height: 1.125em;
color: ${theme.primaryText};

}
h3 {
font-size: 2.2rem;
line-height: 1.25em;
color: ${theme.primaryText};
margin: 0.1em 0 0.1em 0;
}
h4 {
font-size: 1.8rem;
line-height: 1.4em;
color: ${theme.primaryText};
margin: 0.1em 0 0.1em 0;
}
p {
font-size: 1.6rem;
line-height: 1.5em;
color: ${theme.secondaryText};
margin: 0.25em 0 0.25em 0;
}

/* ul {
list-style: none;
padding: 0;
margin: 0;
} */

select {
height: 48px;
font-size: 1.6rem;
width: 100%;
margin: 0;
padding: .5rem 2.25rem .5rem 1rem;
line-height: 1.5;
color: ${theme.primaryText};
background-color: ${theme.inputColor};
border: 0;
border-radius: ${theme.borderRadius};
cursor: pointer;
outline: 0;
/* appearance: none; */
margin-bottom: 2.4rem;
}

/* li {
padding-left: 1em; 
text-indent: -.7em;
&::before {
content: "• ";
color: ${(props) => props.theme.tertiaryText};
margin-right: 1rem;
}

} */

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
select, textarea, input[type="text"], input[type="password"],
input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"],
input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
select, textarea, input[type="text"], input[type="password"],
input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"],
input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
select, textarea, input[type="text"], input[type="password"],
input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"], 
input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
select, textarea, input[type="text"], input[type="password"],
input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"],
input[type="tel"], input[type="url"]{ font-size: 16px; }
}
`

const Layout = ({ children, className }) => {

  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata

  const login = (position) => {
    analytics.handleLoginClick(position);
    window.location.href = `${process.env.GATSBY_FRONT_END_ADDRESS}/login`
  }
  const signup = (position) => {
    analytics.handleSignUpClick(position);
    window.location.href = `${process.env.GATSBY_FRONT_END_ADDRESS}/signup`
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div>
        <Header>
          <HeaderGridItem justify="start">
            <Logo title={siteTitle} />
          </HeaderGridItem>
          <HeaderGridItem justify="center" smallJustify="end" smallCol="3/4">
            <Navigation login={login} signup={signup} />
          </HeaderGridItem>
          <HeaderGridItem justify="end" smallCol="2/3">
            <StyledGridBlock columns="1fr 1fr">
              <Button type="CTA" onClick={() => signup('header')}>Join now!</Button>
              <LoginButton onClick={() => login('header')}>Login</LoginButton>

            </StyledGridBlock>
          </HeaderGridItem>
        </Header>
        <main style={{ minHeight: "100vh" }}>
          {children}
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout

const LoginButton = styled(Button)`
    display: block;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    display: none;
  }
`

const HeaderGridItem = styled.div`
  padding: ${({ theme }) => theme.padding};
  align-items: center;
  justify-self: ${props => props.justify};
  grid-row: 1/2;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.desktop}) {
    grid-column: ${props => props.smallCol};
    justify-self: ${props => props.smallJustify};
  }
`

const StyledGridBlock = styled(GridBlock)`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    grid-template-columns: 1fr;
  }
`