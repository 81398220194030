import styled, { css } from 'styled-components';
import colors from './colors';

const setFontSize = (sizes, lineHeights, breakPoints) =>
  sizes.map(
    (size, i) =>
      `@media screen and (max-width: ${breakPoints[i]}){
      font-size: ${size}rem;
      line-height: ${lineHeights[i]}rem;
    }`
  );

const getColor = (color) => colors[color];

// https://www.bbc.co.uk/gel/guidelines/typography

const breakPointArray = ['1024px', '768px', '480px'];

// Unchanging large prominent text - pricing
const HeadingLarge = styled.h1`
  font-size: 44px;
  line-height: 48px;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([5.2, 4.4, 3.9], [5.6, 4.6, 4.1], breakPointArray)}
  `};
`;

// Canon	Hero or blog post title	28/32	32/36	52/56	44/48
const Heading1 = styled.h1`
  font-size: 44px;
  line-height: 48px;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([5.2, 3.2, 2.8], [5.6, 3.6, 3.2], breakPointArray)}
  `};
`;

// Trafalgar	Article title or section Heading	20/24	24/28	36/40	32/36
const Heading2 = styled.h2`
  font-size: 32px;
  line-height: 36px;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([3.6, 2.4, 2], [4.0, 2.8, 2.4], breakPointArray)}
  `};
`;

// Paragon	Primary headline on indexes	20/24	22/26	28/32	28/32
const Heading3 = styled.h3`
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([2.8, 2.2, 2], [3.2, 2.6, 2.4], breakPointArray)}
  `};
`;

// Double Pica	Sub Heading	20/24	20/24	26/30	24/28
const Heading4 = styled.h4`
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([2.6, 2, 2], [3.0, 2.4, 2.4], breakPointArray)}
  `};
`;

// Great Primer	Headline title or subtitle	18/22	18/22	21/24	20/24
const Heading5 = styled.h5`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([2.1, 1.8, 1.8], [2.4, 2.2, 2.2], breakPointArray)}
  `};
`;

// Pica	Index links, titles & headlines	15/20	16/20	18/22	16/20
const Heading6 = styled.h6`
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([1.8, 1.6, 1.5], [2.2, 2.0, 2.0], breakPointArray)}
  `};
`;

// Minion	Small Heading capitals	12/16	12/16	13/16	12/16
const Heading7 = styled.h6`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([1.3, 1.2, 1.2], [1.6, 1.6, 1.6], breakPointArray)}
  `};
`;

// Body Copy	Article body copy only	15/20	16/22	18/24	16/22
const BodyCopy1 = styled.p`
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([1.8, 1.6, 1.5], [2.4, 2.2, 2.0], breakPointArray)}
  `};
`;

// Long Primer	Index body copy & image captions	15/18	15/18	15/20	14/18
const BodyCopy2 = styled.p`
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([1.5, 1.5, 1.5], [2.0, 1.8, 1.8], breakPointArray)}
  `};
`;

// Brevier	Time stamps and bylines	14/16	14/18	14/18	13/16
const Byline = styled.p`
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin: 0;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color ? getColor(props.color) : props.theme.primaryDark};
  ${css`
    ${setFontSize([1.4, 1.4, 1.4], [1.8, 1.8, 1.6], breakPointArray)}
  `};
`;

// --------- Key text --------

const Paragraph = styled(BodyCopy1)`
  color: ${(props) => props.theme.colors.primaryText};
`;
const ParagraphFaded = styled(BodyCopy1)`
  color: ${(props) => props.theme.colors.lightergrey};
`;

const AccentLink = styled.a`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.primaryText};
  cursor: pointer;
`;

const AccentText = styled.p`
  background-color: ${(props) => props.theme.overlayDark};
  color: white;
  padding: 8px ${(props) => props.theme.padding};
  font-weight: bold;
`;

const Bold = styled.span`
  color: ${(props) => props.theme.primaryDark};
  font-weight: bold;
`;

const Eyebrow = styled.p`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${(props) => props.theme.labelText};
  font-weight: bold;
  letter-spacing: 1.3px;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
`;

const ItemTitle = styled.h4`
  display: inline-block;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  color: ${(props) =>
    props.color === 'light'
      ? props.theme.colors.offWhite
      : props.theme.primaryText};
  border-bottom: ${(props) =>
    props.underline ? `5px solid ${props.underline}` : 'none'};
`;

const LabelText = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
  color: ${(props) => props.theme.labelText};
`;

const LabelTextBold = styled(LabelText)`
  font-weight: 500;
  text-transform: uppercase;
`;

const SectionTitle = styled(Heading2)`
  margin-bottom: 2.5rem;
`;

const PrimaryText = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.primaryText};
`;
const SecondaryText = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.secondaryText};
`;

const TertiaryText = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.tertiaryText};
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};px;
`;

const SubTitle = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.secondaryText};
`;

const ListTitle = styled(Heading6)`
  display: inline-block;
  margin-bottom: ${(props) => (props.margin ? props.theme.padding : 0)};
  border-bottom: ${(props) =>
    props.underline ? `3px solid ${props.underline}` : 'none'};
`;
const ListSubTitle = styled(Byline)`
  color: ${(props) => props.theme.secondaryText};
  ${(props) =>
    props.truncate &&
    css`
      font-size: 14px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 14px * 2;
    `};
`;

const PriceMainText = styled(HeadingLarge)`
  width: auto;
  span {
    vertical-align: baseline;
    margin-right: 5px;
    font-size: 1.6rem;
    color: ${(props) => props.theme.lightgrey};
  }
`;

const PriceAltText = styled(BodyCopy2)`
  color: ${(props) => props.theme.colors.lightgrey};
  /* margin-left: ${(props) => props.theme.padding}; */
`;

const ExplainerText = styled(Eyebrow)``;

const ColorSpan = styled.span`
  color: ${(props) => {
    switch (props.color) {
      case 'accent':
        return props.theme.accent;
      case 'secondary':
        return props.theme.secondary;
      default:
        return props.theme.primaryDark;
    }
  }} !important;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

// --------- CARDS ----------

const CardTitle = styled(Heading3)``;
const CardSubTitle = styled(Heading5)`
  color: ${(props) => props.theme.colors.lightergrey};
`;

// wizard card
const WizardCardSubTitle = styled(CardSubTitle)`
  margin-bottom: 32px;
  text-align: ${(props) => (props.align ? props.align : 'left')};
`;

export {
  Title,
  Eyebrow,
  AccentLink,
  TertiaryText,
  Bold,
  LabelText,
  LabelTextBold,
  AccentText,
  ItemTitle,
  SubTitle,
  SectionTitle,
  SecondaryText,
  PrimaryText,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Heading7,
  BodyCopy1,
  BodyCopy2,
  Byline,
  ListTitle,
  ListSubTitle,
  ExplainerText,
  PriceMainText,
  PriceAltText,
  HeadingLarge,
  ColorSpan,
  CardTitle,
  CardSubTitle,
  Paragraph,
  ParagraphFaded,
  WizardCardSubTitle,
};
