import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(on) {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (on) {
      document.body.style.overflow = 'hidden';
    }
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [on]); // Empty array ensures effect is only run on mount and unmount
}
