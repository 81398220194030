import Palette from './palette';

const principle = 216;
const secondary = principle - 55;

const colors = {
  principleDark: Palette['indigo-900'],
  principle: Palette['indigo-500'],
  principleLight: Palette['indigo-200'],
  principleLightest: Palette['indigo-050'],
  primaryDarkTrans: `hsla(${principle}, 86%, 7%, 0.85)`,
  secondaryDark: Palette['cyan-900'],
  secondaryDarker: Palette['cyan-700'],
  secondary: Palette['cyan-500'],
  secondaryLighter: Palette['cyan-300'],
  secondaryLight: Palette['cyan-100'],
  accent: Palette['pink-500'],
  danger: Palette['red-500'],
  red: Palette['red-500'],
  lightred: Palette['red-200'],
  lightestred: Palette['red-100'],
  greenDark: Palette['green-900'],
  green: Palette['green-500'],
  greenLight: Palette['green-300'],
  yellowDark: Palette['yellow-800'],
  yellow: Palette['yellow-500'],
  yellowLight: Palette['yellow-100'],
  black: Palette['blue-grey-900'],
  darkgrey: Palette['blue-grey-700'],
  grey: Palette['blue-grey-500'],
  lightgrey: Palette['blue-grey-400'],
  lightergrey: Palette['blue-grey-300'],
  lightestgrey: Palette['blue-grey-100'],
  offWhite: Palette['blue-grey-050'],
  white: `hsl(${principle}, 20%, 99%)`,
  gradient: `linear-gradient(to right, hsl(${principle}, 71%, 52%) 0%, hsl(${secondary}, 71%, 52%) 100%)`,
  gradientSE: `linear-gradient(to bottom right, hsl(${principle}, 71%, 52%) 0%, hsl(${secondary}, 71%, 52%) 100%)`,
  gradientAccent: `linear-gradient(to right, hsl(330, 71%, 52%) 0%, hsl(280, 71%, 52%) 100%)`,
  gradientPrimary: `linear-gradient(to right, hsl(${principle}, 71%, 52%) 0%, hsl(${principle}, 71%, 24%) 100%)`,
  gradientPrimarySE: `linear-gradient(to bottom right, hsl(${principle}, 71%, 52%) 0%, hsl(${principle}, 71%, 24%) 100%)`,
  gradientPrimaryDarkSE: `linear-gradient(to bottom right, hsl(${principle}, 71%, 24%) 0%, hsl(${principle}, 71%, 30%) 100%)`,
  gradientPrimarySETrans: `linear-gradient(to bottom right, hsla(${principle}, 71%, 52%, 0.8) 0%, hsla(${principle}, 81%, 52%, 0.2) 100%)`,
  gradientFadeToWhite: `linear-gradient(to bottom, hsla(${principle}, 71%, 90%, 0.7) 0%, hsla(${principle}, 81%, 100%, .8) 40%, hsla(${principle}, 81%, 100%, 1) 100%)`,
};

export default colors;
