const analytics = typeof window !== 'undefined' && window.analytics;

export const init = () => { };

export const event = (action, properties) => {
  window.analytics.track(action, properties);
};

export const pageView = (location) => {
  const name = location.pathname;
  const category = name.split('/');
  window.analytics.page(category[1], name);
};

export const setUser = (thisUser) => {
  const {
    id,
    uid,
    email,
    logins,
    signupdate,
    user,
    first_name,
    last_name,
    billing,
  } = thisUser;
  // const member_tier = thisUser.billing ? 'Premium' : 'Free';
  const member_tier = thisUser.account.role;
  const plan =
    billing && billing.subscriptions
      ? thisUser.billing.subscriptions.data[0].plan.id
      : null;
  const data = {
    uid,
    userId: id,
    email,
    plan,
    logins,
    createdAt: signupdate,
    member_tier,
    user,
    first_name,
    last_name,
  };
  window.analytics.identify(uid, data);
  if (window.LogRocket) {
    // This is an example script - don't forget to change it!
    window.LogRocket.identify(uid, {
      name: user,
      email,
      plan,
      member_tier,
    });
  }
};

export const reset = () => {
  window.analytics.reset();
};

export const handleSignUpClick = (location) => {
  event('Clicked signup button', {
    category: 'account',
    label: location,
    value: 0,
  });
};
export const handleLoginClick = (location) => {
  event('Clicked login button', {
    category: 'account',
    label: location,
    value: 0,
  });
};

export const handlePurchaseClick = (location) => {
  event('Product Added', {
    category: 'account',
    label: location,
    value: 0,
  });
};
