import styled, { css } from 'styled-components';

const buttonColorFunc = (props, normal) => {
  switch (props.type) {
    case 'primary':
      return {
        text: props.theme.white,
        bg: props.theme.primary,
      };
    // return props.theme.primary;
    case 'tertiary':
      return {
        text: props.theme.grey,
        bg: props.theme.colors.lightestgrey,
      };
    case 'submit':
      return {
        text: props.theme.white,
        bg: props.theme.primary,
      };
    // return props.theme.primary;
    case 'accent':
      return {
        text: props.theme.white,
        bg: props.theme.accent,
      };
    // return props.theme.accent;
    case 'CTA':
      return {
        text: props.theme.white,
        bg: props.theme.CTA,
      };
    // return props.theme.CTA;
    case 'success':
      return {
        text: props.theme.white,
        bg: props.theme.success,
      };
    // return props.theme.success;
    case 'danger':
      return {
        text: props.theme.danger,
        bg: props.theme.dangerBG,
      };
    // return props.theme.danger;
    case 'warning':
      return {
        text: props.theme.white,
        bg: props.theme.warning,
      };
    // return props.theme.warning;
    default:
      return {
        text: props.theme.primary,
        bg: normal,
      };
    // return normal;
  }
};

const BaseButton = styled.button`
  position: relative;
  padding: ${(props) => (props.small ? '5px' : props.theme.padding)};
  font-weight: bold;
  font-size: ${(props) => props.theme.buttonFontSize};
  border-radius: ${(props) => props.theme.borderRadius};
  border: none;
  cursor: pointer;
  transition: all ${(props) => props.theme.transitions};
  text-align: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
  &:hover {
    box-shadow: ${(props) => props.theme.bsSpread};
    transform: translateY(-2px);
    /* filter: brightness(110%); */
  }
  &:disabled {
    box-shadow: none;
    opacity: 0.4;
    box-shadow: none;
    transform: none;
    filter: none;
  }
`;

const Button = styled(BaseButton)`
  color: ${(props) => buttonColorFunc(props, props.theme.primaryLightest).text};
  font-weight: bold;
  background: ${(props) =>
    buttonColorFunc(props, props.theme.primaryLightest).bg};
  border-radius: ${(props) => props.theme.borderRadius};
  border: none;
  cursor: pointer;
  /* text-shadow: 1px 1px ${(props) => props.theme.colors.grey}; */
  box-shadow: ${(props) => props.theme.bs};
  transition: all ${(props) => props.theme.transitions};
  text-align: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const ActionButton = styled(Button)`
  display: block;
  width: 100%;
  margin: ${(props) => props.theme.padding} 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const FlatButton = styled(BaseButton)`
  box-shadow: none;
  text-shadow: none;
  background-color: ${(props) =>
    props.selected ? props.theme.accent : props.theme.white};
  border: 1px solid ${(props) => props.theme.colors.lightestgrey};
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white
      : buttonColorFunc(props, props.theme.colors.lightestgrey).text};
  transition: all ${(props) => props.theme.transitions};
  &:hover {
    border: 1px solid
      ${(props) => buttonColorFunc(props, props.theme.colors.lightgrey).bg};
  }
`;

const TertiaryButton = styled(BaseButton)`
  color: ${(props) =>
    props.selected
      ? props.theme.primary
      : buttonColorFunc(props, props.theme.colors.lightgrey).text};
  background-color: transparent;
  box-shadow: none;
  text-shadow: none;
  &:hover {
    position: relative;
    top: 0px;
    box-shadow: none;
  }
`;

const IconButton = styled(TertiaryButton)`
  padding: 0px;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.theme.colors.lightergrey};
      border-radius: 50%;
      padding: 5px;
    `}
`;

const IconBgButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.large ? '72px' : '48px')};
  height: ${(props) => (props.large ? '72px' : '48px')};
  border-radius: 100%;
  color: ${(props) =>
    props.type
      ? buttonColorFunc(props, props.theme.colors.lightgrey).text
      : props.theme.colors.white};
  background-color: ${(props) =>
    props.type
      ? buttonColorFunc(props, props.theme.colors.lightgrey).bg
      : props.theme.primary};
  box-shadow: ${(props) => (props.shadow ? props.theme.bs : 'none')};
`;

const CTAButton = styled.button`
  background: ${(props) => props.theme.colors.accent};
  width: 50%;
  max-width: 200px;
  height: 60px;
  border: none;
  border-radius: 50px;
  box-shadow: ${(props) => props.theme.bs};
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s ease-in;
  &:hover {
    box-shadow: ${(props) => props.theme.bsSpread};
    transform: translateY(-2px);
  }
`;

const DboardButton = styled.button`
  padding: 0.5rem 1rem;
  color: hsl(216, 37%, 50%);
  font-weight: bold;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  text-shadow: 1px 1px hsl(216, 20%, 25%);
  -webkit-transition: all 0.22s;
  transition: all 0.22s;
  background-color: #0f356c;
  &:hover {
    color: hsl(210, 20%, 98%);
  }
  &:after {
    content: ' →';
  }
`;

const LinkButton = styled.button`
  font-size: 1.5rem;
  background: none;
  border: none;
  font-weight: bold;
  width: 100%;
  height: 48px;
  text-align: left;
  margin: 0px !important;
  padding: 16px;
  border-left: 3px solid transparent;
  color: ${(props) => props.theme.colors.lightgrey} !important;
  &:hover {
    color: ${(props) => props.theme.primary} !important;
    border-left: 3px solid ${(props) => props.theme.primary};
    cursor: pointer;
  }
`;

const PlayButton = styled(Button)`
  background-color: ${(props) =>
    props.outcome === 'pass'
      ? props.theme.colors.green
      : props.theme.colors.red};
  width: 80px;
  height: 60px;
  font-size: 2rem;
`;

const TabButton = styled.div`
  border-bottom: 3px solid
    ${(props) => (props.selected ? props.theme.primary : 'transparent')};
  padding: 0 ${(props) => props.theme.padding};
  color: ${(props) =>
    props.selected ? props.theme.primary : props.theme.colors.lightergrey};
  font-weight: bold;
  cursor: pointer;
  transition: border 0.5s ease;
  &:hover {
    border-bottom: 3px solid ${(props) => props.theme.secondary};
    position: relative;
    top: 0px;
    box-shadow: none;
  }
`;

const ConfirmButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.padding};
`;

const PictureButton = styled(Button)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid
    ${(props) => (props.selected ? props.theme.secondary : props.theme.white)};
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    flex-direction: row;
    align-items: left;
    div {
      margin-right: ${(props) => props.theme.padding};
    }
    h4 {
      text-align: left;
    }
    p {
      text-align: right;
      width: 100%;
    }
  }
`;

const ImageButton = styled(BaseButton)`
  margin: 0px;
  padding: 0px !important;
`;

export {
  ActionButton,
  Button,
  FlatButton,
  IconButton,
  IconBgButton,
  LinkButton,
  PlayButton,
  TabButton,
  DboardButton,
  CTAButton,
  ConfirmButton,
  PictureButton,
  TertiaryButton,
  ImageButton,
};
