import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: ${(props) => props.theme.padding};
  width: 100%;
  position: relative;
  /* @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    padding: 0.5rem;
  } */
`;

const WrapperWide = styled(Wrapper)`
  max-width: ${(props) => props.theme.maxWidthWide};
`;

const WrapperNarrow = styled(Wrapper)`
  max-width: ${(props) => props.theme.breakPoints.tablet};
`;
const WrapperText = styled(Wrapper)`
  max-width: ${(props) => props.theme.textWidth};
`;

const Spacer = styled.div`
  position: relative;
  display: block;
  width: ${(props) => (props.width ? `${props.width}rem` : '100%')};
  height: ${(props) => (props.height ? `${props.height}rem` : '1.6rem')};
  border-top: ${(props) =>
    props.borderTop ? `1px solid ${props.theme.secondary}` : 'none'};
`;

const SidebarRight = styled.div`
  /* display: grid;
  grid-template-columns: auto 300px; */

  display: flex;
  flex-direction: row;
  > div:first-child {
    width: calc(100% - 300px);
  }

  > div:nth-child(2) {
    width: 300px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    > div:first-child {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
`;

const SiteLayout = styled.div`
  display: grid;
  grid-template-areas: ${(props) =>
    props.loggedIn
      ? `'nav header' 'nav content' 'nav footer'`
      : `'header' 'content' 'footer'`};
  min-height: 100vh;
  grid-template-columns: ${(props) =>
    props.loggedIn ? '200px calc(100% - 200px)' : '100%'};
  > nav {
    display: ${(props) => (props.loggedIn ? 'block' : 'none')};
  }
  grid-template-rows:
    ${(props) => (props.loggedIn ? '60px' : '0px')} minmax(
      calc(100vh - 95px),
      auto
    )
    auto;
  @media (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-template-columns: 100%;
    grid-template-areas: 'header' 'content' 'footer';
    > nav {
      display: none;
    }
  }
`;

const SplitOnDesktop = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: block;
    width: 100%;
  }
`;

const PageGridCols3 = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 300px) auto 200px;
  grid-auto-rows: auto;
  grid-gap: ${(props) => props.theme.padding};
  @media (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-template-columns: 300px auto;
    > nav {
      display: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-template-columns: 100%;
  }
`;

const PageGridCols2Three = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px 16px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const PageGridCols2 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${(props) => props.theme.padding} 32px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;
const PageGridCols2Reverse = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px 16px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const PaddingBlock = styled.div`
  padding: ${(props) => props.theme.padding};
`;

const FlexBlock = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: ${(props) => (props.align ? props.align : 'center')};
`;
const FlexBlockMotion = styled(motion.div)`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: ${(props) => (props.align ? props.align : 'center')};
`;

const GridBlock = styled.div`
  display: grid;
  grid-template: ${(props) => (props.rows ? props.rows : 'auto')} / ${(props) =>
    props.columns ? props.columns : 'auto'};
  grid-gap: ${(props) => (props.gap ? props.gap : props.theme.padding)};
  justify-items: ${(props) => (props.justify ? props.justify : 'start')};
  align-items: ${(props) => (props.align ? props.align : 'start')};
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-template: ${(props) => {
    const normal = `${props.rows ? props.row : 'auto'} / ${props.columns ? props.columns : 'auto'
      }`;
    return props.responsive ? 'auto / 100%' : normal;
  }};
  }
`;

const GridBlockInverted = styled(GridBlock)`
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    div:nth-child(3) {
      grid-row: 4;
    }
  }
`;

const GridRowToTopOnTablet = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-row: 1 / 2;
  }
`;

const HeaderTopper = styled.div`
  padding-top: ${(props) => (props.small ? '40px' : '400px')};
  margin-bottom: ${(props) => (props.small ? '0px' : '-200px')};
  background: ${(props) =>
    props.color ? props.theme.colors.gradientPrimarySE : 'transparent'};
`;

const Margin = styled.div`
  margin-top: ${(props) => (props.top ? props.theme.padding : '0px')};
  margin-bottom: ${(props) => (props.bottom ? props.theme.padding : '0px')};
  margin-right: ${(props) => (props.right ? props.theme.padding : '0px')};
  margin-left: ${(props) => (props.left ? props.theme.padding : '0px')};
`;

const MarginNegative = styled.div`
  margin-top: ${(props) => (props.top ? props.theme.paddingNegative : '0px')};
  margin-bottom: ${(props) =>
    props.bottom ? props.theme.paddingNegative : '0px'};
  margin-right: ${(props) =>
    props.right ? props.theme.paddingNegative : '0px'};
  margin-left: ${(props) => (props.left ? props.theme.paddingNegative : '0px')};
`;

const PageTitle = styled.div`
  margin-bottom: ${(props) => props.theme.padding};
  > h2 {
    margin-bottom: 0px;
  }
  > p {
  }
`;

const ParagraphBlock = styled.div`
  padding: ${(props) => props.theme.padding};
`;

const ProductGrid = styled.div`
  display: grid;
  /* margin: 0 auto; */
  grid-template-columns: ${(props) =>
    `repeat(auto-fill, minmax(${props.width}px, 1fr))`};
  grid-auto-rows: ${(props) => `minmax(${props.height}px, auto)`};
  gap: 16px;
  justify-items: center;
`;

const ProductRow = styled.div`
  background-color: ${(props) => props.theme.colors.offWhite};
  width: 100%;
  /* max-width: ${(props) =>
    props.width === 'wrapper'
      ? props.theme.maxWidth
      : props.theme.bodyWidthLarge}; */

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: ${(props) => props.theme.padding};
  padding: ${(props) => props.theme.padding} ${(props) => props.theme.padding}
    ${(props) => props.theme.padding}
    ${(props) => {
    if (props.wrapper) {
      return `calc((100vw - 200px - ${props.theme.maxWidth}) / 2)`;
    }
  }};
  @media (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: ${(props) => props.theme.bodyWidth};
    padding: ${(props) => props.theme.padding};
  }

  > div,
  button {
    flex: 0 0 auto;
    margin-right: ${(props) => props.theme.padding};
    &:first-child {
      /* margin-left: ${(props) => props.theme.padding}; */
    }
  }
`;

const Section = styled.div`
  display: block;
  width: 100%;
  height: auto;
  background-color: ${(props) =>
    props.dark ? props.theme.primaryDark : props.theme.white};
`;

const InlineSection = styled.div`
  display: flex;
  align-items: baseline;
  p {
    margin-left: 1rem;
  }
`;

const BackgroundSection = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const SectionSeparator = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.colors.lightestgrey};
  color: ${(props) => props.theme.colors.white};
  padding: 50px 16px;
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : null)};
  box-shadow: ${(props) => (props.inset ? props.theme.bsInsetLarge : null)};
`;
const StyledSection = styled.section`
  position: relative;
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : null)};
`;

const HideOnMobile = styled.div`
  display: block;
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    display: none;
  }
`;
const HideOnTablet = styled.div`
  display: block;
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: none;
  }
`;
const ShowOnTablet = styled.div`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: block;
  }
`;

export {
  Wrapper,
  WrapperWide,
  WrapperNarrow,
  WrapperText,
  PageGridCols3,
  PageGridCols2,
  PageGridCols2Three,
  PageGridCols2Reverse,
  PaddingBlock,
  SiteLayout,
  HeaderTopper,
  SplitOnDesktop,
  ParagraphBlock,
  ProductGrid,
  ProductRow,
  InlineSection,
  BackgroundSection,
  Margin,
  MarginNegative,
  PageTitle,
  SidebarRight,
  FlexBlock,
  FlexBlockMotion,
  GridBlock,
  GridBlockInverted,
  GridRowToTopOnTablet,
  Spacer,
  Section,
  SectionSeparator,
  StyledSection,
  HideOnMobile,
  HideOnTablet,
  ShowOnTablet,
};
