const Palette = {
  'cyan-050': '#E0FCFF',
  'cyan-100': '#BEF8FD',
  'cyan-200': '#87EAF2',
  'cyan-300': '#54D1DB',
  'cyan-400': '#38BEC9',
  'cyan-500': '#2CB1BC',
  'cyan-600': '#14919B',
  'cyan-700': '#0E7C86',
  'cyan-800': '#0A6C74',
  'cyan-900': '#044E54',

  'lime-green-vivid-050': '#F8FFED',
  'lime-green-vivid-100': '#E6FFBF',
  'lime-green-vivid-200': '#CAFF84',
  'lime-green-vivid-300': '#AFF75C',
  'lime-green-vivid-400': '#8DED2D',
  'lime-green-vivid-500': '#6CD410',
  'lime-green-vivid-600': '#5CB70B',
  'lime-green-vivid-700': '#399709',
  'lime-green-vivid-800': '#2E7B06',
  'lime-green-vivid-900': '#1E5303',

  // green
  'green-050': '#E3F9E5',
  'green-100': '#F0FFF4',
  'green-200': '#C6F6D5',
  'green-300': '#9AE6B4',
  'green-400': '#68D391',
  'green-500': '#48BB78',
  'green-600': '#38A169',
  'green-700': '#2F855A',
  'green-800': '#276749',
  'green-900': '#22543D',

  // blue
  'blue-050': '#F0F4F8',
  'blue-100': '#EBF8FF',
  'blue-200': '#BEE3F8',
  'blue-300': '#90CDF4',
  'blue-400': '#63B3ED',
  'blue-500': '#4299E1',
  'blue-600': '#3182CE',
  'blue-700': '#2B6CB0',
  'blue-800': '#2C5292',
  'blue-900': '#2A4365',

  // blue-grey
  'blue-grey-050': '#F0F4F8',
  'blue-grey-100': '#D9E2EC',
  'blue-grey-200': '#BCCCDC',
  'blue-grey-300': '#9FB3C8',
  'blue-grey-400': '#829AB1',
  'blue-grey-500': '#627D98',
  'blue-grey-600': '#486581',
  'blue-grey-700': '#334E68',
  'blue-grey-800': '#243B53',
  'blue-grey-900': '#102A43',

  // grey
  'grey-050': '#F0F4F8',
  'grey-100': '#F7FAFC',
  'grey-200': '#EDF2F7',
  'grey-300': '#E2E8F0',
  'grey-400': '#CBD5E0',
  'grey-500': '#A0AEC0',
  'grey-600': '#718096',
  'grey-700': '#4A5568',
  'grey-800': '#2D3748',
  'grey-900': '#1A202C',

  // blue vivid
  'blue-vivid-050': '#E6F6FF',
  'blue-vivid-100': '#BAE3FF',
  'blue-vivid-200': '#7CC4FA',
  'blue-vivid-300': '#47A3F3',
  'blue-vivid-400': '#2186EB',
  'blue-vivid-500': '#0967D2',
  'blue-vivid-600': '#0552B5',
  'blue-vivid-700': '#03449E',
  'blue-vivid-800': '#01337D',
  'blue-vivid-900': '#002159',

  'indigo-050': '#E0E8F9',
  'indigo-100': '#EBF4FF',
  'indigo-200': '#C3DAFE',
  'indigo-300': '#A3BFFA',
  'indigo-400': '#7F9CF5',
  'indigo-500': '#667EEA',
  'indigo-600': '#5A67D8',
  'indigo-700': '#4C51BF',
  'indigo-800': '#434190',
  'indigo-900': '#3C366B',

  'pink-050': '#FFE0F0',
  'pink-100': '#FAB8D9',
  'pink-200': '#F191C1',
  'pink-300': '#E668A7',
  'pink-400': '#DA4A91',
  'pink-500': '#C42D78',
  'pink-600': '#AD2167',
  'pink-700': '#9B1B5A',
  'pink-800': '#781244',
  'pink-900': '#5C0B33',

  'red-050': '#FFEEEE',
  'red-100': '#FACDCD',
  'red-200': '#F29B9B',
  'red-300': '#E66A6A',
  'red-400': '#D64545',
  'red-500': '#BA2525',
  'red-600': '#A61B1B',
  'red-700': '#911111',
  'red-800': '#780A0A',
  'red-900': '#610404',

  'yellow-050': '#FFFAEB',
  'yellow-100': '#FCEFC7',
  'yellow-200': '#F8E3A3',
  'yellow-300': '#F9DA8B',
  'yellow-400': '#F7D070',
  'yellow-500': '#E9B949',
  'yellow-600': '#C99A2E',
  'yellow-700': '#A27C1A',
  'yellow-800': '#7C5E10',
  'yellow-900': '#513C06',
};

export default Palette;
