import breakpoints from './breakpoints';
import colors from './colors';

const theme = {
  primaryDark: colors.principleDark,
  primary: colors.principle,
  primaryLight: colors.principleLight,
  primaryLightest: colors.principleLightest,
  secondaryDark: colors.secondaryDark,
  secondaryDarker: colors.secondaryDarker,
  secondary: colors.secondary,
  secondaryLighter: colors.secondaryLighter,
  secondaryLight: colors.secondaryLight,
  white: colors.white,
  accent: colors.accent,
  bg: `hsl(240, 100%, 98%)`,
  accentText: colors.red,
  primaryText: colors.black,
  secondaryText: colors.grey,
  tertiaryText: colors.lightergrey,
  danger: colors.red,
  dangerBG: colors.lightestred,
  success: colors.green,
  warning: colors.yellow,
  offSetColor: colors.offWhite,
  labelText: colors.lightergrey,
  placeholder: colors.lightergrey,
  hover: colors.lightestgrey,
  selected: colors.lightestgrey,
  inputColor: colors.offWhite,
  inputDarkColor: colors.white,
  isDragging: colors.white,
  isDraggingOver: colors.lightergrey,
  CTA: colors.gradientAccent,
  maxWidth: '1000px',
  maxWidthWide: '1400px',
  sideBar: colors.black,
  sideBarWidth: '200px',
  buttonFontSize: '1.6rem',
  padding: '1.6rem',
  paddingNegative: '-1.6rem',
  bodyWidthLarge: 'calc(100vw - 200px)',
  bodyWidth: '100vw',
  widePadding: '2.4rem',
  nicePadding: '1rem 2rem',
  bs: '0 2px 4px 0px rgba(0, 0, 0, 0.1)',
  bsSpread: '0 2px 6px 1px rgba(0, 0, 0, 0.18)',
  bsSpreadLarge: '0 2px 50px 16px rgba(0, 0, 0, 0.18)',
  bsInset: 'inset 0px 2px 3px rgba(0,0,0,0.18)',
  bsInsetLarge: 'inset 0px 2px 12px 2px rgba(0,0,0,0.18)',
  overlay: colors.gradientPrimarySETrans,
  overlayWhite: 'hsla(210, 36%, 96%, 0.8)',
  overlayDark: colors.primaryDarkTrans,
  borderRadius: '10px',
  transitions: '0.22s',
  hoverScale: 'scale(1.05)',
  ts: `1px 1px ${colors.grey}`,
  rippleBg: `radial-gradient(circle, ${colors.lightestgrey} 1%, ${colors.lightestgrey} 0.1%) center/15000%`,
  textWidth: `70ch`,
  colors: {
    red: colors.red,
    lightred: colors.lightred,
    greenDark: colors.greenDark,
    green: colors.green,
    greenLight: colors.greenLight,
    black: colors.black,
    white: colors.white,
    accent: colors.accent,
    grey: colors.grey,
    lightgrey: colors.lightgrey,
    lightergrey: colors.lightergrey,
    lightestgrey: colors.lightestgrey,
    offWhite: colors.offWhite,
    yellowDark: colors.yellowDark,
    yellow: colors.yellow,
    yellowLight: colors.yellowLight,
    gradient: colors.gradient,
    gradientSE: colors.gradientSE,
    gradientAccent: colors.gradientAccent,
    gradientPrimary: colors.gradientPrimary,
    gradientPrimarySE: colors.gradientPrimarySE,
    gradientPrimaryDarkSE: colors.gradientPrimaryDarkSE,
    gradientFadeToWhite: colors.gradientFadeToWhite,
  },
  breakPoints: breakpoints,
  animation: {
    easeStandard: [0.4, 0.0, 0.2, 1],
  },
};

export { theme };
