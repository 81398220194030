import React, { useState } from "react";
import { Link } from "gatsby";
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import styled from 'styled-components';
import Portal from '../ui/Portal';
import ModalBackground from '../ui/modals/ModalBackground';
import { GridBlock } from '../styles/layout'
import IconSchoger from "../ui/icons/IconSchoger";
import { Button } from "../styles/buttons";

const MenuItems = [
  {
    path: "/",
    title: "Home"
  },
  {
    path: "/betavision",
    title: "BetaVision"
  },
  {
    path: "/pricing",
    title: "Pricing"
  },
  {
    path: "/blog",
    title: "Blog"
  },
  {
    path: "/about",
    title: "About"
  },
  {
    path: "/contact",
    title: "Contact"
  },

]

const ListLink = (props) => (<li><StyledLink to={props.to}>{props.children}</StyledLink></li>)


const Navigation = (props) => {

  const [showMenu, setShowMenu] = useState(false)

  const handleToggleClick = () => {
    const newState = showMenu ? false : true;
    setShowMenu(newState)
  }

  const listMenuItems = MenuItems.map((menuItem, index) =>
    <ListLink key={index} to={menuItem.path}>{menuItem.title}</ListLink>
  )

  return (
    <>
      <nav className="site-navigation">
        <button onClick={handleToggleClick} className={"menu-trigger" + (showMenu ? " is-active" : "")}>
          <div className="icon-menu-line"><RiMenu3Line /></div>
          <div className="icon-menu-close"><RiCloseLine /></div>
        </button>
        <ul>
          {listMenuItems}
        </ul>
      </nav>
      <Portal>
        <ModalBackground on={showMenu} toggle={handleToggleClick} />
        {showMenu && (
          <StyledSideMenu rows="50px 150px 50px auto" justify="start">
            <IconSchoger button icon="close" buttonAction={handleToggleClick} style={{ justifySelf: 'end' }} />
            <ul>
              {listMenuItems}
            </ul>
            <Button onClick={() => props.login('sideMenu')}>Login</Button>
            <Button type="CTA" onClick={() => props.signup('sideMenu')}>Join now!</Button>
          </StyledSideMenu>

        )}
      </Portal>
    </>
  )
}

export default Navigation

const StyledSideMenu = styled(GridBlock)`
  position: fixed;
  width: 200px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.padding};
  z-index: 2;
  > ul {
    list-style: none;
    padding: 0;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primaryDark};
  transition: all ${({ theme }) => theme.transitions};
  text-decoration: none !important;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  &[aria-current="page"] {
    color: ${({ theme }) => theme.colors.accent};
  }
`