import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import LogoText from '../assets/logo/text-large.png';
import LogoIcon from '../assets/logo/large.png';


const Logo = ({ scrolled, small }) => {


  if (small)
    return (
      <LogoSmallStyled>
        <img src={LogoIcon} height="40" width="40" alt="" />
      </LogoSmallStyled>
    );
  return (
    <LogoStyled scrolled={scrolled ? 'scrolled' : undefined} to="/">
      <img src={LogoText} width="90" height="33" alt="" />
    </LogoStyled>
  );
};



export default Logo

const LogoStyled = styled(Link)`
  display: flex;

  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const LogoSmallStyled = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  margin: ${props => props.theme.padding};
`;